import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout/Layout"
import { MetadataReadonly } from "../../types/contentfulTypes"
import Gtag from "../../utils/classes/Gtag"

import PillButton from "../../components/buttons/PillButton"
import * as TextStyles from "../../components/styles/TextStyles"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Cancel = (props: Props) => {
  const { data } = props

  useEffect(() => {
    if (window.document.location.search === "?ref=checkout.stripe") {
      Gtag.sendEvent("stripe checkout cancelled", "checkout")
    }
  }, [])


  return (
    <Layout {...data}>
      <BackgroundContainer>
        <Wave />
      </BackgroundContainer>
      <Wrapper>
        <Card>
          <Background />
          <Logo src="/images/icons/cancel.svg" alt="logo" />
          <Title>Failed Checkout</Title>
          <Illustration src="/images/icons/mail.png" alt="mail" />
          <Caption>
            You will not be charged since you cancelled in-between the checkout process.
          </Caption>
          <PillButton title="Back to Homepage" onClick={() => navigate("/")} />
        </Card>
      </Wrapper>
    </Layout>
  )
}

export default Cancel

export const query = graphql`
  query CancelQuery {
    contentfulMetadata(slug: { eq: "cancel" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  padding: 300px 20px;
  justify-content: stretch;
  margin: 0 auto;
  z-index: 2;
`

const Card = styled.div`
  position: relative;
  display: grid;
  gap: 10px;
  max-width: 570px;
  width: 100%;
  background: rgba(205, 192, 251, 0.3);
  box-shadow: 0px 16px 30px rgba(69, 42, 124, 0.19);
  backdrop-filter: blur(40px);
  border-radius: 35px;
  padding: 20px;
  margin: 0 auto;
`

const Background = styled.div`
  position: absolute;
  background: linear-gradient(96.43deg, #3630aa 10.26%, #ac6dcd 74.19%);
  box-shadow: 0px 8px 11px rgba(69, 42, 124, 0.35);
  width: 100%;
  height: 126px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`

const Logo = styled.img`
  position: relative;
  width: 42px;
  height: 42px;
  margin: 0 auto;
`

const Title = styled(TextStyles.BodyIntro)`
  position: relative;
  margin: 0 auto;
  color: #ffffff;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`

const Illustration = styled.img`
  position: relative;
  width: 96px;
  height: 96px;
  margin: 0 auto;
`

const Caption = styled(TextStyles.MediumText)`
  max-width: 410px;
  margin: 0 auto;
  text-align: center;
`

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 1293px;
  overflow: hidden;
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/success-wave2.webp");
  background-size: 1440px;
`
